import {
  map,
  join,
  pipe,
  type,
  sortBy,
  toLower,
  split,
  isEmpty,
  isNil,
  endsWith,
  prop,
  groupWith,
  groupBy,
  propEq,
  findIndex,
  flatten,
  sortWith,
  ascend,
  descend,
  sort,
} from "ramda"
import { findBy } from "@leeruniek/functies"
import { toDateTimeFormat } from "lib/leeruniek/time-helpers"
import {
  SCORE_VALUABLE_GRADE,
  TEST_PROVIDER_DISPLAYED_SCORE_FORMATS,
  CITO_SCORE_FORMATS,
  TEST_PROVIDERS_ORDERED,
  YEAR_TIMES_ORDERED,
  TEST_PROVIDERS,
  UNDEFINED_PUPIL_APPROACH,
  DIRECTIONS,
  PUPIL_APPROACHES_ORDERED,
} from "lib/leeruniek/constants"

import slug from "slug"

slug.defaults.mode = "rfc3986"
slug.charmap["/"] = "-"

export const slugify = slug

/**
 * Determines if promise
 *
 * @param  {Object}   input  The input
 *
 * @return {boolean}  True if promise, False otherwise.
 */
export const isPromise = (input) =>
  type(input) === "Object" && typeof input.then === "function"

/**
 * Determines if string
 *
 * @param  {*}        input  The input
 *
 * @return {boolean}  True if string, False otherwise.
 */
export const isString = (input) => type(input) === "String"

/**
 * Evaluate a string literal containing one or more placeholders, and replace
 * with their corresponding values.
 *
 * @param   {String}                  source  String with placeholders
 * @param   {Object<string, string>}  params  Object with values to be replaced
 *
 * @return  {String}                  Interpolated value
 *
 * @example
 * interpolate( "lorem {{ipsum}}", { ipsum: "dolor"} ) // "lorem dolor"
 */
export const interpolate = (source, params = {}) =>
  params.length
    ? source
    : source.replace(/{{([^}]*)}}/g, (match, $1) => params[$1] || match)

/**
 * Remove char from beginning and end of input string
 *
 * @param  {string}  input  Input string
 * @param  {string}  char   Character to be removed
 *
 * @return {string}
 */
export const trim = (input, char) => {
  const safeChar = char === "]" ? "\\]" : char === "\\" ? "\\\\" : char

  return input.replace(new RegExp(`^[${safeChar}]+|[${safeChar}]+$`, "g"), "")
}

/**
 * Throw error wrapper for ternary operator
 *
 * @param  {Object}  error  The error
 *
 * @return {undefined}
 */
export const throwError = (error) => {
  throw error
}

/**
 * Determines if absolute url
 *
 * @param  {string}   input  The input
 *
 * @return {boolean}  True if absolute url, False otherwise
 */
export const isURL = (() => {
  const startsWithRegExp = /^https?:\/\//i

  return (input) => startsWithRegExp.test(input)
})()

/**
 * Calculate position in order to fit over or under another item
 *
 * @param  {Object}   arg1             The argument 1
 * @param  {Object}   arg1.items       Source array
 * @param  {integer}  arg1.toId        To identifier
 * @param  {boolean}  arg1.isUnder     Indicates if over or under toId item
 *
 * @return {number}
 */
export const getPositionByIndex = ({ items, toId, isUnder }) => {
  const toItem = findBy({ id: toId })(items)
  const toIndex = items.indexOf(toItem)
  const neighbour = items[isUnder ? toIndex + 1 : toIndex - 1]

  return isNil(items) || isEmpty(items)
    ? 1
    : neighbour
      ? (toItem.position + neighbour.position) / 2
      : isUnder
        ? items[items.length - 1].position + 1
        : items[0].position - 1
}

/**
 * Transform a string from camelCase to underscore_case
 *
 * @param  {string}  source  Source input
 *
 * @return {string}
 */
export const toUnderscore = (source) =>
  pipe(split(/(?=[A-Z])/), map(toLower), join("_"))(source)

/**
 * Truncate string.
 * The output string will be of maxLength characters.
 * 6 characters will get utilized for the tripleDotString
 *
 * @param {input} source Source object
 *
 * @param  {string}  input  The string to truncate
 * @param  {integer} maxLength The length of the output string
 * All strings with bigger length will be truncated.
 *
 * @returns {string}
 */
export const truncate = (input, maxLength = 25) => {
  if (!input || input.length < maxLength) {
    return input
  }

  const inputStr = input.trim()
  const tripleDotString = "..."

  return `${inputStr
    .substring(0, maxLength - tripleDotString.length)
    .trim()}${tripleDotString}`
}

/**
 * Truncate Number.
 * Rounds down the last decimal that would be rounded up using toFixed.
 *
 * @returns {number}
 */
export const truncateFloat = (input, decimalPlaces = 0) => {
  const y = Math.pow(10, decimalPlaces)
  return Math.floor(input * y) / y
}

/**
 * Return number of words in string.
 *
 * @returns {number}
 */
export const getWordCount = (str) => {
  return str.trim().split(/\s+/).length
}

/**
 * Determine if user working in Leeruniek
 *
 *
 * @param  {string}  email  User email
 *
 * @returns {boolean}
 */

export const isLeeruniekEmployee = (email) => endsWith("@leeruniek.nl", email)

export const capitalize = (string) =>
  !string || !string.length
    ? string
    : string.charAt(0).toUpperCase() + string.slice(1)

export const getNoteCreateMeta = ({ dateCreated }) => {
  if (isNil(dateCreated)) {
    return ""
  }
  return `${toDateTimeFormat(dateCreated)}`
}

export const scrollToDOMElement = (
  element,
  { anchorPosition = "middle", topMargin = 0 },
) => {
  if (!element) {
    return
  }
  const componentDOMRect = element.getBoundingClientRect()
  const startPoint = window.scrollY - topMargin
  let scrollPoint

  switch (anchorPosition) {
    case "top":
      scrollPoint = startPoint + componentDOMRect.top
      break
    case "bottom":
      scrollPoint = startPoint + componentDOMRect.bottom
      break
    default:
      scrollPoint = startPoint + componentDOMRect.y
      break
  }

  window.scrollTo({
    left: componentDOMRect.x,
    top: scrollPoint,
    behavior: "smooth",
  })
}

export const getAvailableSubjects = (
  subjects,
  grade,
  nationalTests = [],
  testProvider = {},
) => {
  if (!isEmpty(nationalTests) && !isEmpty(testProvider)) {
    const subjectsIds = new Set(
      nationalTests.reduce((acc, nt) => {
        if (nt.testProvider === testProvider.id) {
          acc.push(nt.subjectId)
        }
        return acc
      }, []),
    )

    const filteredSubjects = subjects.filter((subject) =>
      subjectsIds.has(subject.id),
    )
    if (testProvider.name === TEST_PROVIDERS.CITO) {
      return grade < SCORE_VALUABLE_GRADE
        ? filteredSubjects.filter((subject) => subject.isPreschool)
        : filteredSubjects.filter((subject) => !subject.isPreschool)
    }
    return filteredSubjects
  }
  return grade < SCORE_VALUABLE_GRADE
    ? subjects.filter((subject) => subject.isPreschool)
    : subjects.filter((subject) => !subject.isPreschool)
}

export const groupScoresByTestProvider = (scores) =>
  groupBy(prop("testProvider"), scores)

export const getLatestScoreProviderId = (scores) => {
  const sortedScores = sort(
    (scoreA, scoreB) => new Date(scoreA.dateTaken) - new Date(scoreB.dateTaken),
    scores,
  )
  return (
    sortedScores[sortedScores.length - 1] &&
    sortedScores[sortedScores.length - 1]["testProvider"]
  )
}

export const getNationalTestName = (
  nationalTests,
  subjectId,
  testProviderId,
) => {
  const nationalTest =
    nationalTests &&
    nationalTests.find(
      (nt) => nt.subjectId === subjectId && nt.testProvider === testProviderId,
    )

  if (nationalTest) {
    return nationalTest.name
  }
}

export const getDefaultScoreTypeForProvider = (testProvider) => {
  if (!testProvider) {
    return CITO_SCORE_FORMATS.LEVEL_VALUE
  }
  if (
    new Set(TEST_PROVIDER_DISPLAYED_SCORE_FORMATS[testProvider.name]).has(
      CITO_SCORE_FORMATS.LEVEL_VALUE,
    )
  ) {
    return CITO_SCORE_FORMATS.LEVEL_VALUE
  } else {
    const scoreFormats = TEST_PROVIDER_DISPLAYED_SCORE_FORMATS[
      testProvider.name
    ].filter((el) => el !== CITO_SCORE_FORMATS.TEST_CODE_EXTRA)
    return scoreFormats[0]
  }
}

// Sort YearclassTestMoment scores by grade and yeartime

export const sortYearclassTestMomentScores = (scores) => {
  return pipe(
    sortBy(prop("yearClassGrade")),
    groupWith(
      (a, b) => prop("yearClassGrade", a) === prop("yearClassGrade", b),
    ),
    map((gradeScores) =>
      sort(
        (scoreA, scoreB) =>
          findIndex((yt) => yt === scoreA.yearTime, YEAR_TIMES_ORDERED) -
          findIndex((yt) => yt === scoreB.yearTime, YEAR_TIMES_ORDERED),
        gradeScores,
      ),
    ),
    flatten,
  )(scores)
}

// Group YearclassTestMoment notes by approaches and sort them by grade,yeartime and forwardLooking property
export const groupAndSortYearclassTestMomentNotes = (
  notes,
  scores,
  direction = DIRECTIONS.ASC,
) => {
  //Add yearClassGrade, yearTime fields from related YearclassTestMoment score to be able sort them
  const notesWithYearTimeData = notes.reduce((acc, note) => {
    const noteTest = scores.find(
      (s) => s.mostCommonYearclassTestMomentId === note.yearclassTestMoment,
    )
    if (!isNil(noteTest)) {
      return [
        ...acc,
        {
          ...note,
          yearClassGrade: noteTest.yearClassGrade,
          yearTime: noteTest.yearTime,
          sortApproach: isNil(note.approach)
            ? UNDEFINED_PUPIL_APPROACH
            : note.approach,
        },
      ]
    }
    return acc
  }, [])

  const sortedNotes = sortWith([
    ascend(prop("yearClassGrade")),
    ascend((note) => YEAR_TIMES_ORDERED.indexOf(note.yearTime)),
    descend(prop("yearclassName")),
    ascend((note) => {
      const index = PUPIL_APPROACHES_ORDERED.indexOf(note.sortApproach)

      // place undefined approaches at the beginning when ordering ascending,
      // otherwise place them at the end
      if (index === -1) {
        return direction === DIRECTIONS.ASC
          ? index
          : PUPIL_APPROACHES_ORDERED.length + 1
      }

      // place other approaches according to their position in PUPIL_APPROACHES_ORDERED
      return direction === DIRECTIONS.ASC ? index : -index
    }),
    ascend((note) => (note.forwardLooking ? 1 : 0)),
  ])(notesWithYearTimeData)

  return direction === DIRECTIONS.ASC ? sortedNotes : sortedNotes.reverse()
}

// Sort School notes for the timeline section by order from top to bottom:
// by school-year
// by subject position
// by approach / evaluation
export const sortSchoolNotes = (notes, subjects) => {
  return sortWith([
    descend(prop("calendarYear")),
    ascend((note) => {
      const subject = subjects.find((s) => s.id === note.subject)
      return subject ? subject.position : 0
    }),
    ascend((note) => (note.forwardLooking ? 0 : 1)),
  ])(notes)
}

export const getDefaultTestProviderForGroupResults = (
  scores,
  testProviders,
  selectedScoreId,
) => {
  if (isEmpty(scores)) {
    //If no scores available we use CITO as default
    return testProviders.find((tp) => tp.name === TEST_PROVIDERS.CITO)["id"]
  }
  if (!isNil(selectedScoreId)) {
    //If selectedScoreId exists return its testProvider
    let scoreFromSelectedYearclassTestMoment = scores.find(
      (score) => score.mostCommonYearclassTestMomentId === selectedScoreId,
    )
    if (!isNil(scoreFromSelectedYearclassTestMoment)) {
      return scoreFromSelectedYearclassTestMoment.testProvider
    }
  }
  const groupedScores = groupScoresByTestProvider(scores)
  // look for latest scores from different test providers
  const latestScoresByProvider = Object.values(groupedScores).map(
    (scoresGroup) => {
      return sortYearclassTestMomentScores(scoresGroup)[scoresGroup.length - 1]
    },
  )

  if (latestScoresByProvider.length === 1) {
    // if it's only one score, we return its testProvider
    return latestScoresByProvider[0]["testProvider"]
  } else if (latestScoresByProvider.length > 1) {
    const sortedLatestScores = sortYearclassTestMomentScores(
      latestScoresByProvider,
    )
    const latestScore = sortedLatestScores[sortedLatestScores.length - 1]
    //look if scores with same yearClassGrade and yearTime are presented
    const scoresWithSameTestMoment = sortedLatestScores.filter(
      (score) =>
        score.yearClassGrade === latestScore.yearClassGrade &&
        score.yearTime === latestScore.yearTime,
    )

    if (scoresWithSameTestMoment.length === 1) {
      //If it's no scores from same test time we use testProvider from found latest score
      return latestScore["testProvider"]
    } else {
      // Sort by number of pupils taking the tests in descending order and choose
      // the provider of the test that most pupils took. When the same number of
      // pupils take different tests, we use default provider based on these two
      // tests. For example: if CITO scores exist, we use CITO as default provider
      // because it is first in TEST_PROVIDERS_ORDERED array
      const highestNumPupilsInScore = Math.max(
        ...scoresWithSameTestMoment.map(
          (score) => score.pupilTestScoreIds.length,
        ),
      )
      const providerIdsWithHighestNumPupils = scoresWithSameTestMoment
        .filter(
          (score) => score.pupilTestScoreIds.length === highestNumPupilsInScore,
        )
        .map((item) => item.testProvider)
      const sortedProviders = sort(
        (a, b) =>
          findIndex(propEq("name", b.name), TEST_PROVIDERS_ORDERED) -
          findIndex(propEq("name", a.name), TEST_PROVIDERS_ORDERED),
        testProviders,
      ).filter((provider) =>
        providerIdsWithHighestNumPupils.includes(provider.id),
      )
      return sortedProviders[0].id
    }
  }
}

export const getDefaultTestProviderForSchoolAverages = (
  averages,
  testProviders,
) => {
  if (isEmpty(averages)) {
    //If no scores available we use CITO as default
    return testProviders.find((tp) => tp.name === TEST_PROVIDERS.CITO)["id"]
  }
  const sortedAverages = pipe(
    sortBy(prop("year")),
    groupWith((a, b) => prop("year", a) === prop("year", b)),
    map((scores) =>
      sort(
        (scoreA, scoreB) =>
          findIndex((yt) => yt === scoreA.yearTime, YEAR_TIMES_ORDERED) -
          findIndex((yt) => yt === scoreB.yearTime, YEAR_TIMES_ORDERED),
        scores,
      ),
    ),
    flatten,
  )(averages)
  const lastAverage = sortedAverages[sortedAverages.length - 1]
  //look if scores with same year and yearTime are present
  const similarAvgsFromOtherProviders = sortedAverages.filter(
    (avg) =>
      avg.year === lastAverage.year &&
      avg.yearTime === lastAverage.yearTime &&
      avg.testProvider !== lastAverage.testProvider,
  )
  if (isEmpty(similarAvgsFromOtherProviders)) {
    //If it's no scores from same test time we use testProvider from found latest score
    return lastAverage.testProvider
  } else {
    // in case if score(s) from different test provider but from same test time exists, we use default provider based on available scores;
    const sortedProviders = testProviders
      .filter((p) => TEST_PROVIDERS_ORDERED.indexOf(p.name) !== -1)
      .sort(
        (a, b) =>
          TEST_PROVIDERS_ORDERED.indexOf(a.name) -
          TEST_PROVIDERS_ORDERED.indexOf(b.name),
      )

    const priorityProvider = sortedProviders.find((tp) =>
      similarAvgsFromOtherProviders
        .map((avg) => avg.testProvider)
        .includes(tp.id),
    )

    if (priorityProvider) {
      return priorityProvider.id
    }
  }
}

export const getCssVariable = (variableName) =>
  getComputedStyle(document.documentElement).getPropertyValue(variableName)

export const getComparisonString = (originalValue, valueForComparing) => {
  if (originalValue > valueForComparing) {
    return "above"
  } else if (originalValue < valueForComparing) {
    return "below"
  } else {
    return "on"
  }
}

export const roundNumberDecimals = (value, decimalsRange) =>
  Number(Math.round(value + "e" + decimalsRange) + "e-" + decimalsRange)

export const sortYearclassTestNotes = (notes) =>
  sortWith([
    ascend((note) => {
      return PUPIL_APPROACHES_ORDERED.indexOf(note.approach)
    }),
    ascend(prop("yearclassName")),
  ])(notes)
